import { useMemo } from 'react';
import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';

import { Container, HeroContainer, Section } from 'components/Container';
import { LegalPageFooter } from 'components/LegalPageFooter';
import { List } from 'components/List';

import { privacyPolicyContent, privacyPolicyHeaders } from 'page-data/privacyPolicy';
import { stringToAnchorId } from 'helpers/stringToAnchorId';

import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

const lastUpdatedDate: Date = new Date('2024-02-27');

const PrivacyPolicy: FC<LocalizedPageProps> = () => {
  const renderPrivacyPolicyContent = useMemo(
    () =>
      privacyPolicyContent.map(({ heading, content }) => (
        <Container key={heading}>
          <Section>
            <h2 id={stringToAnchorId(heading)}>{heading}</h2>
            {content}
          </Section>
        </Container>
      )),
    []
  );

  return (
    <Layout>
      <Seo />
      {/* Header section */}
      <HeroContainer background="cream" skipColoration>
        <Section>
          <h1>Sharesight Privacy Policy</h1>
          <p id="header">
            This Privacy Policy applies to your use of Sharesight and our website. By visiting our
            site and using Sharesight, you accept our Privacy Policy.
          </p>
          <br />
          <List noIndent itemGap={0} items={privacyPolicyHeaders} />
        </Section>
      </HeroContainer>

      {/* Wrap in div to reset container background colour to white */}
      {renderPrivacyPolicyContent}

      {/* To the top button */}
      <LegalPageFooter elementId="header" date={lastUpdatedDate} />
    </Layout>
  );
};

export default PrivacyPolicy;
