import { GatsbyLink } from 'components/Links';
import { List } from 'components/List';

import { stringToAnchorId } from 'helpers/stringToAnchorId';

import type { ReactNode } from 'react';
import type { Clause } from 'components/List';

interface PrivacyPolicyContent {
  heading: string;
  content: ReactNode;
}

const getSectionLink = (id: string) => `/privacy-policy/#${stringToAnchorId(id)}`;
const getLinkTitle = (id: string) => `${id} | Privacy Policy | Sharesight`;

const headerLink = (text: string): Clause => ({
  id: text,
  content: (
    <GatsbyLink textColor="black" to={getSectionLink(text)} title={getLinkTitle(text)}>
      {text}
    </GatsbyLink>
  ),
});

export const privacyPolicyHeaders: Clause[] = [
  headerLink('Sharesight collects your personal data'),
  headerLink('How we use the personal data we hold about you'),
  headerLink('To whom we may disclose your personal data'),
  headerLink('Use of cookies and tracking technology'),
  headerLink('Credit card information'),
  headerLink('How we secure and manage your personal data'),
  headerLink('How you can update and gain access to your personal data'),
  headerLink('Email opt-out'),
  headerLink('Third party applications'),
  headerLink('Changes to this Privacy Policy'),
  headerLink('Terms of Use'),
  headerLink('How to contact us'),
];

export const privacyPolicyContent: PrivacyPolicyContent[] = [
  {
    heading: 'Sharesight collects your personal data',
    content: (
      <>
        <p>
          <GatsbyLink to="/">Sharesight</GatsbyLink> is an online share market investment portfolio
          management service. Sharesight stores information that is entered by you, automatically
          captured, or is imported at your request. That information can include personal data.
        </p>
        <p>
          “Personal data” is any information related to a natural person that can be used to
          directly or indirectly identify that person.
        </p>
        <p>Sharesight may collect personal data when you:</p>
        <List
          items={[
            { id: 'visit', content: 'Visit our websites' },
            { id: 'use', content: 'Use Sharesight' },
          ]}
        />
      </>
    ),
  },
  {
    heading: 'How we use the personal data we hold about you',
    content: (
      <>
        <p>
          We may use personal data we collect about you for the primary purpose for which it is
          collected and for other secondary purposes that are related to the primary purpose of
          collection.
        </p>
        <p>We generally use personal data we hold about you to:</p>
        <List
          items={[
            { id: '1', content: 'provide you with products or services you have requested;' },
            {
              id: '2',
              content: 'communicate with you;',
            },
            {
              id: '3',
              content:
                'provide you with ongoing news and information about our products and services;',
            },
            {
              id: '4',
              content:
                'do other things necessary and incidental to the provision of products and services by us to you;',
            },
            {
              id: '5',
              content: 'produce and publish aggregated portfolio statistics; and/or',
            },
            {
              id: '6',
              content: 'help us manage and enhance our products and services.',
            },
          ]}
        />
        <p>
          We also use screen capturing software to analyse individual customer usage for the purpose
          of improving our user interface.
        </p>
      </>
    ),
  },
  {
    heading: 'To whom we may disclose your personal data',
    content: (
      <>
        <p>
          We may disclose personal data we hold about you to a third party for any purpose that is
          directly or indirectly related to the normal use of Sharesight.
        </p>
        <p>
          If we sell our business, personal data may be part of the assets transferred to the
          purchaser.
        </p>
      </>
    ),
  },
  {
    heading: 'Use of cookies and tracking technology',
    content: (
      <>
        <p>
          Sharesight uses ‘cookies’ and other measures to monitor traffic patterns and to serve you
          more efficiently when you visit the site. Cookies do not contain information by which we
          can identify you but they identify your computer to our servers. We work with third party
          advertisers to collect and use information about your use of the site in order to
          advertise Sharesight products which may be of interest to you.
        </p>
        <p>
          You can block cookies by activating the setting on your browser that allows you to refuse
          the setting of all or some cookies or reject all or some cookies on the cookie consent
          banner. However, if you block all cookies (including essential cookies) you may not be
          able to access all or parts of our website. You can change your cookie preferences at any
          time by clicking on 'Manage Cookies' in the footer. Read more about cookie consent in our{' '}
          <GatsbyLink to="cookie-policy">Cookie Policy</GatsbyLink>.
        </p>
      </>
    ),
  },
  {
    heading: 'Credit card information',
    content: (
      <>
        <p>
          If you choose to pay by credit card your details are encrypted and securely stored by
          Direct Payment Solutions Limited to allow your credit card to be billed on a recurring
          basis.
        </p>
        <p>
          Sharesight does not store your credit card details and they cannot be accessed by
          Sharesight staff.
        </p>
      </>
    ),
  },
  {
    heading: 'How we secure and manage your personal data',
    content: (
      <>
        <p>
          We protect the personal data we collect from misuse, loss, unauthorised access,
          modification or disclosure by various means including firewalls, password access, secure
          servers and encryption of credit card transactions.
        </p>
        <p>It is your responsibility to keep your password safe.</p>
        <p>
          Your personal data may be transferred to, and processed in, countries other than the
          country you live in. For example, some of our data is hosted on AWS servers located in the
          U.S. If you are a non-U.S. resident, this means that your personal data will be
          transferred to the U.S.
        </p>
      </>
    ),
  },
  {
    heading: 'How you can update and gain access to your personal data',
    content: (
      <>
        <p>
          You can update your personal data by logging into your account and changing your personal
          data at any time. If you are unable to update your personal data for any reason, you can
          contact us and we will do it for you. Sharesight will provide you with your data on
          request.
        </p>
        <p>
          Paying subscribers who cancel their paid subscription will be downgraded to the Sharesight
          Free plan. Any of your data which is in excess of the Free plan allowance may be
          permanently deleted if you stop paying for the Sharesight service.
        </p>
        <p>Your data will be permanently deleted at your request.</p>
      </>
    ),
  },
  {
    heading: 'Email opt-out',
    content: (
      <>
        <p>
          Sharesight will send you emails covering a variety of topics such as account activation,
          billing information, product updates, newsletters, share price alerts and portfolio
          performance updates.
        </p>
        <p>
          Non-essential email communications will clearly describe how you can be removed from the
          mailing list.
        </p>
      </>
    ),
  },
  {
    heading: 'Third party applications',
    content: (
      <p>
        Sharesight contains links enabling the electronic transfer of data within third-party
        applications. Sharesight takes no responsibility for the privacy practices or content of
        these applications.
      </p>
    ),
  },
  {
    heading: 'Changes to this Privacy Policy',
    content: (
      <p>
        We reserve the right to make amendments to this Privacy Policy at any time. If you have
        objections to the Privacy Policy, you should not access or use Sharesight.
      </p>
    ),
  },
  {
    heading: 'Terms of Use',
    content: (
      <p>
        This Privacy Policy is to be read in conjunction with Sharesight’s{' '}
        <GatsbyLink to="/sharesight-terms-of-use">Terms of Use</GatsbyLink>
      </p>
    ),
  },
  {
    heading: 'How to contact us',
    content: (
      <>
        <p>To make an access request, please contact our Privacy Officer by:</p>
        <List
          isNumbered
          items={[
            {
              id: '1',
              content: 'Email: contact@sharesight.com; or',
            },
            {
              id: '2',
              content: 'Post: PO Box 27063, Marion Square, Wellington 6141',
            },
          ]}
        />
      </>
    ),
  },
];
